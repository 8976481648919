@import "fontello";
@import "utils";

html {
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  background-color: #000;
  min-width: 320px;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.body--error {
    background-color: #000;
  }
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

a[href],
button {
  touch-action: manipulation;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

// Firefox puts an inner border on inputs and buttons
input::-moz-focus-inner {
  border: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  -ms-interpolation-mode: bicubic;
}

.image-fix {
  display: block;
  width: 100%;
  height: auto;
}

textarea {
  box-sizing: border-box;
  resize: vertical;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

//Icone importate da fontello
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"]:after,
[class*=" icon-"]:after {
  width: auto;
  margin: 0;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Contenitore principale del sito
.container {
  margin: 0 auto;
  max-width: $container;
  padding: 0 $container-padding;
  width: 100%;
}

//Bottone generico
.button {
  display: inline-block;

  &.button--outlined {
    > a, > button {
      background-color: transparent;
      border: 1px solid #ccc;
      color: #fff;

      .no-touchevents &:hover {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  .section__text--static & {
    margin: 1em 0;
  }

  .movie__intro-poster & {
    margin: 35px 0;

    @media (max-width: ($l-tablet - 1)) {
      display: none;
    }
  }

  .guide__show-details & {
    padding: 0 15px 20px;
    width: 100%;

    @media (min-width: $s-tablet) {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: 230px;
    }
  }

  > a,
  > button {
    align-items: center;
    background-color: $primary;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-weight: 900;
    font-size: 12px;
    height: 35px;
    justify-content: center;
    letter-spacing: 0.6px;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    @include transition;

    @media (min-width: $s-tablet) {
      height: 40px;
      min-width: 145px;
    }

    .no-touchevents &:hover {
      background-color: $primaryDarken;
    }

    .guide__show-details & {
      text-transform: none;
    }
  }
}
