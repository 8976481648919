$xs-mobile: 360px;
$s-mobile: 425px;
$l-mobile: 600px;
$s-tablet: 768px;
$l-tablet: 1024px;
$medium: 1280px;
$wide: 1440px;
$l-wide: 1680px;
$extreme: 1920px;

$container: 1192px;
$container-skin: 1040px;
$container-padding: 20px;
$primary: #e80000;
$primaryDarken: #c40000;
$text-grey: #a3a3a3;
$border: #666;
$grey: #f9f9f9;
$facebook: #3b5998;
$twitter: #55acee;
$whatsapp: #2ab200;
$tonightfilms: #f5c518;
$tonightfilmsDarken: #caa83d;
$series: #c11adf;
$movie: #ff6c00;
$sport: #1386df;

//Broadcaster
$netflix: #e50914;
$apple-tv-plus: #444444;
$amazon-prime-video: #0f79af;
$disney-plus: #279aff;
$chili: #03ddbd;
$now-tv: #00a2ab;
$timvision: #039bbe;
$infinity: #6927ba;
$sky-go: #0064d7;
$rai-play: #0099f2;
$apple-itunes: #ff36c4;
$google-play-movies: #ed3b3b;
$microsoft-store: #0078d7;
$playstation: #002055;
$rakuten-tv: #b10a2b;
$youtube-premium: #ff0000;
$mubi: #001489;
$mediaset-play: #9d4de1;
$discovery-plus: #262931;
$vvvvid: #ff0020;
$starz-play-amazon: #000;

@mixin transition($what: all, $time: 0.3s, $how: ease) {
    -webkit-transition: $what $time $how;
    transition: $what $time $how;
}

@mixin loading {
    &:after {
        animation: sk-scaleout 1s infinite ease-in-out;
        background-color: $primary;
        border-radius: 100%;
        content: "";
        display: block;
        height: 30px;
        margin: 25px auto;
        width: 30px;

        @media (min-width: $s-tablet) {
            height: 40px;
            margin: 50px auto;
            width: 40px;
        }

        .no-js & {
            display: none;
        }
    }
}

@mixin hidden() {
    opacity: 0;
    backface-visibility: hidden;
    visibility: hidden;
}

@mixin visible() {
    opacity: 1;
    backface-visibility: visible;
    visibility: visible;
}

/*==================
    Animations
==================*/
@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes trans-from-right {
    0% {
        transform: translateX(120%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes trans-from-bottom {
    0% {
        transform: translateY(120%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }

    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }

    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }

    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }

    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}
