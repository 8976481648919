/*===============
    Imports
===============*/
@import "normalize";
@import "swiper/css";
@import "swiper/css/free-mode";
@import "swiper/css/lazy";
@import "swiper/css/navigation";
@import "common";
@import "utils";

/*====================
    Custom Rules
====================*/

//Le immagini che non hanno l'attributo src (per via del loading) le nascondo
img {
  &.error,
  &:not([src]) {
    visibility: hidden;
  }

  &.lazy {
    opacity: 0;

    @include transition(opacity);

    &.loaded {
      opacity: 1;
    }

    .no-js & {
      display: none !important;
    }
  }
}

//Loading generico e loading sullo slider
.loading,
.swiper-lazy-preloader {
  @include loading;
}

.swiper-container {
  position: relative;
  overflow: hidden;
}

.swiper-lazy-preloader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.swiper-lazy {
  opacity: 0;
  @include transition(opacity);

  &.swiper-lazy-loaded {
    opacity: 1;
  }

  .no-js & {
    display: none !important;
  }
}

.app {
  background: #000;
  position: relative;
}

//Header del sito
.app__header {
  background-color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 0 15px;
  width: 100%;
}

.header__main {
  > div {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.header__logo {
  align-items: center;
  display: flex;

  img {
    display: block;
  }
}

.header__logo-img {
  height: auto;
  width: 200px;

  @media (min-width: $l-tablet) {
    height: 50px;
    width: 326px;
  }
}

.header__logo-icon {
  height: auto;
  margin-left: 10px;
  width: 32px;

  @media (min-width: $l-tablet) {
    margin-left: 20px;
    width: 52px;
  }
}

.main__header {
  margin-top: 30px;

  @media (min-width: $s-tablet) {
    margin-top: 50px;
  }

  > div {
    position: relative;
  }
}

//Footer del sito
.app__footer {
  border-top: 1px solid $border;
  font-size: 13px;
  margin-top: 100px;

  @media (min-width: $s-tablet) {
    margin-top: 200px;
  }
}

.app__footer-openindex {
  align-items: baseline;
  color: #fff;
  display: flex;
  justify-content: center;

  @media (min-width: $s-tablet) {
    display: none;
  }

  &:before {
    font-size: 11px;
    padding-left: 7px;
    order: 2;
  }

  &.is-open {
    margin-bottom: 20px;

    span {
      &:before {
        content: attr(data-close-text);
      }
    }
  }

  span {
    margin-right: 3px;

    &:before {
      content: attr(data-open-text);
    }
  }
}

.app__footer-index {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &:not(.is-open) {
    @media (max-width: $s-tablet - 1) {
      display: none;
    }
  }

  > * {
    @media (max-width: $l-tablet - 1) {
      width: 100%;
    }
  }
}

.company {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 15px 10px;

  @media (min-width: $s-tablet) {
    font-size: 13px;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 10px;

    > * {
      @media (max-width: $l-tablet - 1) {
        width: 100%;
      }
    }
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.links__item {
  > a {
    display: block;
    padding: 5px 10px;
    @include transition(color);

    @media (min-width: $s-tablet) {
      padding: 5px 15px;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

//Titolo della sezione
.section__title {
  font-weight: 900;
  font-size: 30px;
  position: relative;
  z-index: 5;

  @media (min-width: $s-tablet) {
    font-size: 40px;
    text-align: center;
  }

  @media (min-width: $l-tablet) {
    font-size: 50px;
  }

  @media (min-width: $medium) {
    font-size: 60px;
  }

  &.section__title--small {
    text-align: left;

    @media (min-width: $l-tablet) {
      font-size: 55px;
    }
  }

  &.section__title--subsection {
    align-items: center;
    display: flex;
    font-size: 25px;
    margin-bottom: 30px;

    @media (min-width: $s-tablet) {
      font-size: 35px;
    }
  }

  .author__avatar + & {
    display: inline-block;
    vertical-align: middle;
  }

  .body--error & {
    color: #fff;
    text-align: center;
  }

  .body--video & {
    color: #fff;
  }

  .main__header-back + & {
    @media (min-width: $s-tablet) {
      padding: 0 70px;
    }
  }

  .main__header--catalogue & {
    color: #fff;
  }

  .movies__wrapper & {
    margin-bottom: 30px;
  }

  .main__header--search & {
    font-size: 27px;
    text-align: left;

    @media (min-width: $s-tablet) {
      font-size: 32px;
    }

    @media (min-width: $l-tablet) {
      font-size: 37px;
    }

    @media (min-width: $medium) {
      font-size: 42px;
    }

    small {
      display: block;
      font-size: 16px;

      @media (min-width: $s-tablet) {
        font-size: 19px;
      }

      @media (min-width: $l-tablet) {
        font-size: 22px;
      }

      @media (min-width: $medium) {
        font-size: 25px;
      }
    }
  }

  mark {
    background: none;
    color: $primary;
    margin: 0;
  }
}

//Testo della sezione
.section__text {
  font-size: 17px;
  letter-spacing: 0.1px;
  line-height: 1.6;
  margin-top: 20px;

  @media (min-width: $s-tablet) {
    font-size: 18px;
    letter-spacing: 0.15px;
    margin-top: 30px;
  }

  @media (min-width: $medium) {
    font-size: 20px;
    letter-spacing: 0.2px;
    margin-top: 40px;
  }

  &.section__text--small {
    font-size: 14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }

    @media (min-width: $medium) {
      font-size: 18px;
    }
  }

  &.section__text--static {
    > div {
      &:not(:first-child) {
        margin-top: 20px;

        @media (min-width: $s-tablet) {
          margin-top: 30px;
        }

        @media (min-width: $medium) {
          margin-top: 40px;
        }
      }
    }
  }

  &:not(.section__text--static) {
    a {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      color: $primary;
      text-decoration: none;
      position: relative;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
      z-index: 2;

      .body--video & {
        text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
        background-image: linear-gradient(#000 50%, rgba(232, 0, 0, 0.6) 0);
      }
    }
  }

  &:not(.is-expanded) {
    &.section__text--static {
      p {
        &:not(:first-child) {
          display: block;
        }
      }
    }

    p {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  h2,
  h3 {
    color: #fff;
  }

  h2 {
    font-size: 16px;

    @media (min-width: $s-tablet) {
      font-size: 18px;
    }

    @media (min-width: $wide) {
      font-size: 25px;
    }
  }

  h3 {
    font-size: 14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
    }

    @media (min-width: $wide) {
      font-size: 22px;
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table__wrapper {
    margin: 1em 0;

    @media (max-width: $s-tablet - 1) {
      overflow-y: auto;
      position: relative;
    }
  }

  table {
    border: 1px solid #000;
    width: 100%;

    th,
    td {
      padding: 10px;

      @media (min-width: $s-tablet) {
        padding: 10px 15px;
      }
    }

    th {
      background-color: #000;
      border-right-color: #000;
      color: #fff;
      text-align: left;

      @media (max-width: $s-tablet - 1) {
        font-size: 12px;
        line-height: 1.2;
      }
    }

    td {
      vertical-align: top;

      @media (max-width: $s-tablet - 1) {
        font-size: 10px;
        line-height: 1;
      }

      &:first-child,
      &:last-child {
        width: 100px;

        @media (min-width: $s-tablet) {
          width: 250px;
        }
      }
    }
  }

  ul,
  ol {
    margin-left: 0;
    padding-left: 2em;

    @media (min-width: $s-tablet) {
      padding-left: 3em;
    }

    > li {
      padding-left: 5px;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    background-image: linear-gradient(#000 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 22px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
    z-index: 2;

    .body--video & {
      background-image: linear-gradient(#000 50%, rgba(232, 0, 0, 0.6) 0);
      text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
    }
  }
}

//Pagina di errore
.error__image {
  margin-top: 15px;
  text-align: center;

  @media (min-width: $s-tablet) {
    margin-top: 25px;
  }

  @media (min-width: $l-tablet) {
    margin-top: 35px;
  }

  img, video {
    display: inline-block;
    height: auto;
    max-width: 75%;
  }
}

//Guida TV
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(10deg);
  }

  24% {
    transform: rotate(-9deg);
  }

  28% {
    transform: rotate(8deg);
  }

  32% {
    transform: rotate(-7deg);
  }

  36% {
    transform: rotate(6deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  44% {
    transform: rotate(4deg);
  }

  48% {
    transform: rotate(-3deg);
  }

  52% {
    transform: rotate(2deg);
  }

  56% {
    transform: rotate(1deg);
  }

  60% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1,1);
  }

  30% {
    opacity: .3;
  }

  60% {
    opacity: 0;
    transform: scale(1.5,1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1.5,1.5);
  }
}

@keyframes pulseButton {
  0% {
    transform: scale(.97);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(232,0,0,0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(232,0,0,0);
    transform: scale(.97);
  }
}

.guide__channels-fxd {
  background-color: #000;
  height: 65px;
  left: 0;
  position: fixed;
  top: 85px;
  width: 100%;
  z-index: 99;

  + .guide__header {
    margin-top: 64px;
  }
}

.guide__channels {
  background-color: #000;
  height: 65px;

  @media (min-width: $s-tablet) {
    height: 75px;
  }

  .guide & {
    position: sticky;
    top: 0;
    z-index: 15;
  }

  .guide__strip & {
    background-color: #000;
    border-bottom: 1px solid #111;
    border-top: 1px solid #111;

    @media (max-width: ($s-tablet - 1)) {
      margin: 20px 0;
    }
  }

  > div {
    > div {
      display: flex;
      max-width: calc(#{$container} - (20px * 2));
      padding: 0;
    }
  }
}

.guide__channels-slider {
  margin: 0;
  padding: 10px 0;
  width: 100%;

  @media (max-width: ($container - 1)) {
    padding: 10px 20px;
  }

  @media (max-width: ($s-tablet - 1)) {
    padding: 10px 15px;
  }
}

.guide__channels-item {
  align-items: center;
  display: flex;
  opacity: .8;
  transition: opacity .3s ease;
  width: auto;

  &:not(.swiper-slide):not(.guide__channels-item--fixed) {
    padding-top: 2px;

    &:last-child {
      padding-right: 15px;
    }
  }

  &:not(.guide__channels-item--fixed):not(:last-child) {
    padding-right: 10px;
  }

  &:not(.guide__channels-item--fixed) {
    .guide__channels-image {
      background-color: #fff;
    }
  }

  &.guide__channels-item--fixed {
    background-color: rgba(0, 0, 0, 0.95);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    flex-shrink: 0;
    margin: 10px 0;
    opacity: 1;
    padding-right: 20px;
    position: relative;
    z-index: 10;

    @media (max-width: ($container - 1)) {
      padding: 0 20px;

      .guide__strip & {
        padding-left: 0;
      }
    }

    @media (max-width: ($s-tablet - 1)) {
      padding: 0 15px;
    }

    + .swiper,
    + .swiper-container {
      overflow: hidden;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% - 75px);

      @media (min-width: $s-tablet) {
        padding-left: 20px;
        padding-right: 20px;
      }

      .guide__strip & {
        @media (max-width: ($s-tablet - 1)) {
          width: calc(100% - 60px);
        }
      }
    }

    .guide__strip & {
      background-color: rgba(0, 0, 0, 0.95);
    }

    > button {
      border-radius: 50%;
      overflow: hidden;

      &:before {
        background-color: $primary;
        border-radius: 50%;
        content: "";
        display: block;
        height: 45px;
        left: 15px;
        position: absolute;
        top: 0;
        width: 45px;
        z-index: -1;

        @media (min-width: $s-tablet) {
          height: 55px;
          left: 20px;
          width: 55px;
        }

        @media (min-width: $container) {
          left: 0;
        }

        .guide__strip & {
          left: 0;
        }
      }
    }
  }

  &.guide__channels-item--current {
    opacity: 1;

    > a {
      position: relative;

      > span {
        background-color: #333;
        display: block;
      }
    }
  }

  .no-touchevents &:hover {
    opacity: 1;
  }

  > button, > a {
    display: block;
    height: 45px;
    width: 45px;

    @media (min-width: $s-tablet) {
      height: 55px;
      width: 55px;
    }

    &.is-anim {
      &:after {
        animation: pulse 3s linear 5.7s infinite;
        background-color: #b17e75;
        border-radius: 50%;
        bottom: 0;
        content: "";
        display: block;
        height: 45px;
        left: 15px;
        position: absolute;
        width: 45px;
        z-index: -2;

        @media (min-width: $s-tablet) {
          height: 55px;
          left: 20px;
          width: 55px;
        }

        @media (min-width: $container) {
          left: 0;
        }

        .guide__strip & {
          left: 0;
        }
      }

      .guide__channels-image {
        animation: shake 3s linear 5s infinite;
        transform-origin: 50% 100%;
      }
    }

    > span {
      bottom: -10px;
      display: none;
      height: 3px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}

.guide__channels-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 3px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &:after {
    left: 50%;
    margin: -15px;
    position: absolute;
    top: 50%;
  }

  &[layout=fixed] {
    .guide__channels-item--fixed & {
      padding-bottom: 0;
      position: relative;
      top: 5px;
    }
  }

  .guide__channels-item--fixed & {
    background-position: 50% 150%;
    background-size: 42px 37px;

    @media (min-width: $s-tablet) {
      background-size: 52px 47px;
    }
  }
}

.guide__channels-btn {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 21px;
  height: 65px;
  justify-content: center;
  position: absolute;
  top: 0;
  @include transition;
  width: 40px;
  z-index: 10;

  @media (min-width: $s-tablet) {
    height: 75px;
  }

  .no-touchevents &:hover {
    background-color: #000;
  }

  .no-touchevents .swiper:hover &,
  .no-touchevents .swiper-container:hover & {
    display: flex;
  }
}

.guide__channels-btn--prev {
  left: 0;

  &:after {
    @media (min-width: $s-tablet) {
      right: 100%;
    }
  }
}

.guide__channels-btn--next {
  right: 0;

  &:after {
    @media (min-width: $s-tablet) {
      left: 100%;
    }
  }

  .touchevents & {
    display: flex;
  }
}

.guide__header {
  background-color: #000;
  color: #fff;
  padding: 20px 0 30px;
  text-align: center;

  &.guide__header--channel {
    background-color: #333;
    margin-bottom: 25px;
    padding: 20px 0;

    @media (min-width: $s-tablet) {
      margin-bottom: 35px;
      padding: 30px 0;
    }
  }

  &.guide__header--chart {
    background-color: $tonightfilms;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    padding: 20px 0;

    @media (min-width: $s-tablet) {
      margin-bottom: 35px;
      padding: 30px 0;
    }
  }

  > div {
    position: relative;
  }

  strong {
    display: block;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.24px;
    margin-top: 10px;

    @media (min-width: $s-tablet) {
      font-size: 14px;
      letter-spacing: 0.28px;
      margin-top: 20px;
    }
  }

  small {
    align-items: center;
    color: #333;
    display: flex;
    font-weight: 400;
    font-size: 12px;
    justify-content: center;
    letter-spacing: 0.24px;
    margin-top: 15px;
    text-align: center;

    @media (min-width: $s-tablet) {
      margin-top: 25px;
    }

    > * {
      margin-left: 5px;
    }
  }
}

.guide__header-back {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 900;
  font-size: 14px;
  left: 10px;
  position: absolute;
  top: -3px;
  z-index: 10;

  .no-touchevents &:hover {
    &:before {
      background-color: #fff;
      color: #000;
    }
  }

  @media (min-width: $s-tablet) {
    left: $container-padding;
    top: 3px;
  }

  @media (min-width: $l-tablet) {
    top: 10px;
  }

  @media (min-width: $medium) {
    top: 6px;
  }

  @media (min-width: $l-wide) {
    top: 12px;
  }

  &:before {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 11px;
    flex-shrink: 0;
    height: 35px;
    justify-content: center;
    margin-right: 10px;
    @include transition;
    width: 35px;

    @media (min-width: $s-tablet) {
      border: solid 2px #fff;
      height: 40px;
      width: 40px;
    }

    @media (min-width: $l-tablet) {
      font-size: 12px;
      height: 45px;
      width: 45px;
    }
  }

  .guide__header--chart & {
    top: -5px;

    @media (min-width: $s-tablet) {
      top: -3px;
    }

    &:before {
      color: #000;

      @media (min-width: $s-tablet) {
        border-color: #000;
      }
    }

    .no-touchevents &:hover {
      &:before {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }

  ~ h1, ~ strong {
    @media (max-width: ($s-tablet - 1)) {
      padding: 0 40px;
    }
  }
}

.guide__header-title {
  font-weight: 900;
  font-size: 20px;
  position: relative;
  z-index: 5;

  @media (min-width: $s-tablet) {
    font-size: 30px;
    margin: 0 auto;
    max-width: 80%;
  }
}

.guide__body {
  > div {
    @media (min-width: $l-tablet) {
      display: flex;
      justify-content: center;
    }
  }
}

.guide__content {
  @media (min-width: $l-tablet) {
    width: calc(100% - 340px);
  }
}

.guide__content-text {
  border-bottom: 1px solid $border;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 1.6;

  @media (max-width: ($s-tablet - 1)) {
    margin-left: -$container-padding;
    margin-right: -$container-padding;
    padding: 0 $container-padding 25px;
  }

  @media (min-width: $s-tablet) {
    padding: 0 25px 35px;
  }

  @media (min-width: $medium) {
    font-size: 17px;
    letter-spacing: 0.175px;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    background-image: linear-gradient(#000 50%, rgba(232, 0, 0, 0.6) 0);
    background-repeat: repeat-x;
    background-position: 0 22px;
    background-size: 1px 1px;
    color: $primary;
    text-decoration: none;
    position: relative;
    text-shadow: 3px 0 #000, 2px 0 #000, 1px 0 #000, -1px 0 #000, -2px 0 #000, -3px 0 #000;
    z-index: 2;
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
    padding: 0;
  }
}

.guide__aside {
  flex-shrink: 0;

  @media (max-width: ($l-tablet - 1)) {
    display: none;
    margin-bottom: 35px;
    order: -1;
    text-align: center;
  }

  @media (max-width: ($s-tablet - 1)) {
    margin-bottom: 25px;
  }

  @media (min-width: $l-tablet) {
    margin-left: 40px;
    width: 300px;
  }
}

.guide__days {
  border-bottom: 1px solid $border;
  position: relative;

  @media (max-width: ($s-tablet - 1)) {
    margin-left: -$container-padding;
    margin-right: -$container-padding;
    padding: 20px calc(#{$container-padding} + 40px + 8px);
  }

  @media (min-width: $s-tablet) {
    padding: 20px 107.5px;
  }

  .guide__highlight + & {
    border-top: 1px solid $border;
  }
}

.guide__days-slider {
  &.swiper,
  &.swiper-container {
    opacity: 0;
    position: static;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: hidden;
  }

  &.swiper-initialized {
    opacity: 1;
    visibility: visible;
  }

  .swiper-button-prev,
  .swiper-button-next {
    align-items: center;
    background: #000;
    border: 1px solid $border;
    border-radius: 5px;
    color: $primary;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    margin-top: 0;
    position: absolute;
    top: 20px;
    transition: all .3s ease;
    width: 40px;
    z-index: 10;

    @media (min-width: $s-tablet) {
      font-size: 21px;
      height: 55px;
      width: 55px;
    }

    &:after {
      display: none;
    }

    &:focus {
      outline: none;
    }

    .no-touchevents &:hover {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
  }

  .swiper-button-prev {
    left: $container-padding;

    @media (min-width: $s-tablet) {
      left: 25px;
    }
  }

  .swiper-button-next {
    right: $container-padding;

    @media (min-width: $s-tablet) {
      right: 25px;
    }
  }
}

.guide__days-item {
  margin-left: 0;
  width: calc(100%/5);

  > a {
    color: #fff;
    display: block;
    font-weight: 700;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
    width: 40px;

    @media (min-width: $s-tablet) {
      width: 55px;
    }

    &:not(.is-active) {
      .no-touchevents &:hover {
        time {
          border-color: $primary;
          color: $primary;
        }
      }
    }

    &.is-active {
      time {
        background-color: $primary;
        border-color: $primary;
        color: #fff;
      }
    }
  }

  time {
    align-items: center;
    border: 1px solid $border;
    border-radius: 5px;
    display: flex;
    font-size: 16px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.32px;
    transition: border-color .3s ease, color .3s ease;
    width: 100%;

    @media (min-width: $s-tablet) {
      font-size: 22px;
      height: 55px;
      letter-spacing: 0.45px;
    }
  }

  small {
    display: block;
    font-size: 12px;
    margin-top: 3px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      margin-top: 7px;
    }
  }
}

.guide__slots {
  @media (max-width: ($s-tablet - 1)) {
    height: 91px;
    margin-left: -$container-padding;
    margin-right: -$container-padding;
  }

  @media (min-width: $s-tablet) {
    height: 116px;
  }

  > div {
    border-bottom: 1px solid $border;
  }

  ul {
    align-items: baseline;
    display: flex;
    justify-content: space-around;
    padding: 20px $container-padding;

    @media (min-width: $s-tablet) {
      padding: 20px 25px;
    }

    > li {
      @media (min-width: $s-tablet) {
        padding: 0 10px;
      }
    }
  }

  a {
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.24px;
    text-align: center;

    @media (min-width: $s-tablet) {
      font-size: 14px;
      letter-spacing: 0.28px;
    }

    @media (min-width: $medium) {
      font-size: 16px;
      letter-spacing: 0.32px;
    }

    &:before {
      display: block;
      transition: color .3s ease;
    }

    &.is-active,
    .no-touchevents &:hover {
      &:before {
        color: $primary;
      }
    }

    &.icon-clock {
      &:before {
        font-size: 21px;

        @media (min-width: $s-tablet) {
          font-size: 32px;
        }
      }
    }

    &.icon-morning {
      &:before {
        font-size: 25px;

        @media (min-width: $s-tablet) {
          font-size: 39px;
        }
      }
    }

    &.icon-coffee {
      &:before {
        font-size: 27px;

        @media (min-width: $s-tablet) {
          font-size: 42px;
        }
      }
    }

    &.icon-moon {
      &:before {
        font-size: 21px;

        @media (min-width: $s-tablet) {
          font-size: 32px;
        }
      }
    }

    &.icon-moon-full {
      &:before {
        font-size: 22px;

        @media (min-width: $s-tablet) {
          font-size: 35px;
        }
      }
    }

    &.icon-live {
      &:before {
        font-size: 18px;

        @media (min-width: $s-tablet) {
          font-size: 28px;
        }
      }
    }

    span {
      display: block;
      margin-top: 10px;

      @media (min-width: $s-tablet) {
        margin-top: 15px;
      }
    }
  }
}

.guide__sections {
  @media (min-width: $s-tablet) {
    padding: 25px $container-padding 0;
  }

  + .guide__sections {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
  }
}

.guide__loading {
  @include loading;
  margin: 0;
}

.guide__section {
  margin-bottom: 40px;
  position: relative;

  @media (min-width: $s-tablet) {
    margin-bottom: 45px;
  }

  &:focus {
    outline: none;
  }
}

.guide__section-header {
  display: block;
  height: 70px;

  .guide & {
    position: sticky;
    top: 62px; //65px - 3px border-radius
    z-index: 13;

    @media (min-width: $s-tablet) {
      top: 72px; //75px - 3px border-radius
    }
  }

  .guide__strip-slider & {
    > div {
      border-radius: 5px;
      margin: 0;
    }
  }

  .guide__sections + .guide__sections & {
    padding: 0 $container-padding;
  }

  > div {
    align-items: center;
    background-color: #333;
    color: #fff;
    display: flex;
    font-weight: 400;
    height: 100%;
    margin-left: -$container-padding;
    margin-right: -$container-padding;
    padding: 0 $container-padding;

    @media (min-width: $s-tablet) {
      border-radius: 5px;
    }

    > div {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  h2 {
    font-size: 22px;
    letter-spacing: 0.4px;
    line-height: 1;

    small {
      display: block;
      font-size: 12px;
      letter-spacing: 0.24px;
      margin-bottom: 2px;
    }
  }

  strong {
    align-self: flex-end;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.26px;
    margin-bottom: 2px;
    margin-left: 5px;
    margin-top: 22px;
  }
}

.guide__section-shows {
  padding-top: 20px;

  .guide__sections + .guide__sections & {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }
}

.guide__section-shows-slot {
  &:not(:first-child) {
    margin-top: 25px;

    @media (min-width: $s-tablet) {
      margin-top: 40px;
    }
  }

  h3 {
    align-items: center;
    background-color: #333;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    height: 50px;
    letter-spacing: 0.32px;
    margin-bottom: 20px;
    padding: 0 20px;

    &:before {
      color: rgba(255,255,255,.75);
      margin-right: 20px;
    }

    &.icon-morning {
      &:before {
        font-size: 25px;
      }
    }

    &.icon-coffee {
      &:before {
        font-size: 27px;
      }
    }

    &.icon-moon {
      &:before {
        font-size: 21px;
      }
    }
  }
}

.guide__section-media {
  height: 0;
  margin-top: 25px;
  padding-bottom: 56.25%;
  position: relative;

  @media (min-width: $s-tablet) {
    margin-top: 40px;
  }

  .guide__slots + & {
    margin-top: 0;

    @media (max-width: $s-tablet - 1) {
      margin-bottom: 25px;
    }
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__show-list {
  &.guide__show-list--xl {
    margin-top: 30px;

    .movie__section & {
      @media (max-width: ($s-tablet - 1)) {
        margin-top: 15px;
      }
    }

    .guide__show-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &.guide__show-list--chart {
    .guide__show-item {
      .guide__show-button {
        border-radius: 5px;
        padding: 10px;

        &.is-active, &[aria-expanded=true] {
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
}

.guide__show-item {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.28px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .guide__strip-slider &,
  .post__content & {
    align-items: center;
    display: flex;
    padding: 0 15px;
  }

  .guide__strip-slider & {
    color: #fff;
  }
}

.guide__show-button {
  background-color: transparent;
  border: none;
  color: inherit;
  font-weight: 400;
  padding: 0 15px;
  text-align: left;
  width: 100%;

  &:not(.is-active):not([aria-expanded=true]) {
    position: relative;

    &:after {
      background-color: #000;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      .guide__show-list--chart & {
        border-radius: 3px;
      }
    }
  }

  &.is-active, &[aria-expanded=true] {
    background-color: #222;
    padding: 15px;
    position: sticky;
    top: 64px; /* slider canali */
    z-index: 2;

    @media (min-width: $s-tablet) {
      top: 74px; /* slider canali */
    }

    .guide--channel & {
      top: 64px; /* slider canali */

      @media (min-width: $s-tablet) {
        top: 74px; /* slider canali */
      }
    }

    > div, .guide__show-caption {
      &:before {
        transform: rotate(180deg);

        .guide__show-list--xl & {
          margin-top: -8px;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  @at-root {
    button#{&}, header#{&} {
      cursor: pointer;
    }
  }

  > div {
    padding-right: 25px;
    position: relative;

    &:before {
      color: #aaa;
      font-size: 14px;
      position: absolute;
      right: 0;
      top: 2px;
      z-index: 5;

      .guide__show-list--xl & {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .guide__show-list--xl & {
      align-items: center;
      display: flex;
    }
  }
}

.guide__show-caption {
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
  position: relative;
  width: 100%;
  z-index: 5;

  &:before {
    color: #e9e9e9;
    flex-shrink: 0;
    font-size: 14px;
    margin-left: auto;
    margin-right: -10px;
    order: 3;
    padding: 0 10px;
    position: relative;
    top: 2px;
  }

  .guide__show-list--xl & {
    display: block;
    padding-right: 45px;
    position: relative;
    width: calc(100% - 80px);

    &:before {
      margin: -7px 0 0;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 50%;
    }
  }
}

.guide__show-cover {
  align-self: flex-start;
  border: solid 1px $border;
  border-radius: 5px;
  display: block;
  flex-shrink: 0;
  height: 60px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  @include transition(border-color);
  width: 60px;
  z-index: 5;

  @at-root {
    a#{&} {
      .no-touchevents &:hover {
        border-color: $primary;
      }
    }
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__show-maininfo {
  flex-shrink: 0;
  margin-top: 2px;
  margin-right: 15px;

  .guide__show-list--xl & {
    color: #fff;
    margin-bottom: 5px;

    @media (max-width: ($s-tablet - 1)) {
      font-size: 12px;
      letter-spacing: .24px;
    }
  }

  time {
    font-weight: 900;

    &:not(:only-child) {
      &:after {
        content: "|";
        margin: 0 10px;
      }
    }

    .guide__show-list--xl & {
      font-weight: 400;
    }
  }

  span {
    display: inline-block;
    white-space: nowrap;
  }
}

.guide__show-title {
  display: block;
  line-height: 1.3;

  @at-root {
    a#{&} {
      color: $primary;
      text-decoration: underline;
    }
  }

  .guide__show-item--live &,
  .guide__show-item--series &,
  .guide__show-item--movie &,
  .guide__show-item--sport & {
    &:before {
      border: 1px solid #ccc;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      font-size: 10px;
      font-weight: 900;
      letter-spacing: 0.1px;
      margin-right: 7px;
      padding: 1px 7px;
      pointer-events: none;
      position: relative;
      text-transform: uppercase;
      top: -1px;

      @media (min-width: $s-tablet) {
        padding: 1px 10px;
      }
    }
  }

  .guide__show-item--live & {
    &:before {
      background-color: $primary;
      border: none;
      color: #fff;
      content: "LIVE";
    }
  }

  .guide__show-item--series & {
    &:before {
      content: "SERIE TV";
    }
  }

  .guide__show-item--movie & {
    &:before {
      content: "FILM";
    }
  }

  .guide__show-item--sport & {
    &:before {
      content: "SPORT";
    }
  }

  .guide__show-button.is-active &,
  .guide__show-button[aria-expanded=true] & {
    font-weight: 700;
  }

  .guide__show-list--xl & {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 1.15;
  }
}

.guide__show-review {
  margin-top: 10px;

  strong {
    color: #333;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.28px;

    &:before {
      color: $tonightfilms;
      margin-right: 10px;
    }
  }
}

.guide__show-details {
  display: none;
  padding-bottom: 3px;

  &.is-open,
  .guide__show-button[aria-expanded=true] + & {
    background-color: #222;
    display: block;
  }
}

.guide__show-media {
  background-color: #000;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  img, iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__show-info {
  line-height: 1.47;
  padding: 20px 15px;

  &:first-child {
    padding-top: 0;
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.guide__show-meta {
  line-height: 1.47;
  padding: 0 15px 20px;

  > ul {
    border-top: 1px solid $border;
    padding-top: 20px;
  }
}

.guide__show-next {
  padding: 0 15px 20px;

  span {
    border-top: 1px solid $border;
    display: block;
    margin-bottom: 10px;
    padding-top: 20px;
  }

  ul {
    > li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  time {
    color: #aaa;
  }
}

.guide__highlight {
  margin-top: 20px;

  .guide__content > & {
    margin: 0 0 20px;

    @media (min-width: $s-tablet) {
      margin: 0 0 40px;
      padding: 0 25px;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media (min-width: $s-tablet) {
        margin-top: 40px;
      }
    }
  }

  .guide__sections + .guide__sections & {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }

  > strong, > a > strong {
    display: block;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.24px;
    padding-bottom: 10px;
  }
}

.guide__highlight-show {
  border: solid 1px $border;
  border-radius: 5px;
  font-weight: 400;
  overflow: hidden;
}

.guide__highlight-show-inner {
  background-color: #000;
  display: flex;
  padding: 20px;

  &[data-programme-id] {
    cursor: pointer;
  }
}

.guide__highlight-show-image {
  flex-shrink: 0;
  margin-right: 10px;
  width: 65px;

  > div {
    background-color: #fff;
    height: 0;
    padding-bottom: 150%;
    position: relative;
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__highlight-show-caption {
  font-size: 14px;
  letter-spacing: 0.28px;

  > * {
    display: block;
  }

  time {
    margin-bottom: 2px;
  }

  span:first-of-type {
    font-size: 12px;
    letter-spacing: 0.24px;
    margin-bottom: 10px;
  }

  span:last-of-type {
    margin-top: 10px;
  }

  strong {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 10px;
  }
}

.guide__show-channels {
  display: flex;
  font-size: 12px;
  letter-spacing: 0.24px;
  overflow: hidden;
  padding: 10px 20px;

  .guide__show-details & {
    background-color: #000;
    margin: 0 3px;
    width: calc(100% - 6px);
  }

  span {
    display: block;
    flex-shrink: 0;
    margin-right: 15px;
  }

  a {
    font-weight: 700;
    line-height: 1.3;
    transition: color .3s ease;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 10px;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.guide__network {
  border-radius: 5px;
  color: #fff;
  display: flex;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &.guide__network--netflix {
    background-color: $netflix;
  }

  &.guide__network--apple-tv-plus {
    background-color: $apple-tv-plus;
  }

  &.guide__network--amazon-prime-video {
    background-color: $amazon-prime-video;
  }

  &.guide__network--disney-plus {
    background-color: $disney-plus;
  }

  &.guide__network--now-tv {
    background-color: $now-tv;
  }

  &.guide__network--timvision {
    background-color: $timvision;
  }

  &.guide__network--infinity {
    background-color: $infinity;
  }

  &.guide__network--sky-go {
    background-color: $sky-go;
  }

  &.guide__network--rai-play {
    background-color: $rai-play;
  }

  &.guide__network--apple-itunes {
    background-color: $apple-itunes;
  }

  &.guide__network--google-play-movies {
    background-color: $google-play-movies;
  }

  &.guide__network--microsoft-store {
    background-color: $microsoft-store;
  }

  &.guide__network--playstation {
    background-color: $playstation;
  }

  &.guide__network--rakuten-tv {
    background-color: $rakuten-tv;
  }

  &.guide__network--youtube-premium {
    background-color: $youtube-premium;
  }

  &.guide__network--mubi {
    background-color: $mubi;
  }

  &.guide__network--mediaset-play {
    background-color: $mediaset-play;
  }

  &.guide__network--chili {
    background-color: $chili;
  }

  &.guide__network--discovery-plus {
    background-color: $discovery-plus;
  }

  &.guide__network--vvvvid {
    background-color: $vvvvid;
  }

  &.guide__network--starz-play-amazon-channel {
    background-color: $starz-play-amazon;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.28px;
    margin: 5px 0 0;

    @media (min-width: $s-tablet) {
      font-size: 18px;
      letter-spacing: 0.36px;
      margin: 20px 0 0;
    }
  }
}

.guide__network-logo {
  flex-shrink: 0;
  margin-right: 15px;
  width: 65px;

  @media (min-width: $s-tablet) {
    margin-right: 40px;
  }

  > div {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.guide__network-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: ($s-tablet - 1)) {
    margin-left: calc((65px + 15px) *-1);
    margin-top: 40px;
    width: calc(100% + 65px + 15px);
  }

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  a {
    align-items: center;
    background-color: #000;
    border-radius: 5px;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.28px;
    justify-content: center;
    text-align: center;
    transition: all .3s ease;

    .no-touchevents &:hover {
      background-color: $primary;
      color: #fff;
    }
  }
}

.guide__sections-more {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    padding: 0 25px;
  }

  form {
    width: 100%;
  }

  button {
    align-items: center;
    animation: pulseButton 1.5s infinite;
    background-color: $primary;
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(232,0,0,.5);
    color: #fff;
    display: flex;
    font-weight: 700;
    font-size: 22px;
    height: 80px;
    justify-content: center;
    letter-spacing: 0.5px;
    padding: 0 20px;
    position: relative;
    text-align: center;
    transition: background-color .3s ease;
    width: 100%;

    &:before {
      left: 25px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .no-touchevents &:hover {
      background-color: $primaryDarken;
    }
  }
}

.guide__filters {
  background-color: rgba(0,0,0,.4);
  font-weight: 400;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100%;

  &:not(#filters) {
    backface-visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease .1s,visibility .3s ease .2s,z-index 0s ease .3s;
    visibility: hidden;
    z-index: -1000000000; //sennò il banner pubblicitario va sopra
    &.is-open {
      backface-visibility: visible;
      opacity: 1;
      transition: opacity .3s ease 0s, visibility .3s ease 0s, z-index ease 0s;
      visibility: visible;
      z-index: 1000000000; //sennò il banner pubblicitario va sopra
      .guide__filters-inner {
        transform: translateX(0);
        transition: transform .3s ease .2s;
      }
    }

    .guide__filters-inner {
      transform: translateX(-100%);
      transition: transform .3s ease 0s;
    }
  }
}

.guide__filters-close {
  align-items: center;
  background-color: #fff;
  border: solid 1px #000;
  border-radius: 50%;
  display: flex;
  font-size: 10px;
  height: 35px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  transition: color .3s ease;
  width: 35px;
  z-index: 10;

  .no-touchevents &:hover {
    color: $primary;
  }
}

.guide__filters-inner {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  padding: 55px 0 35px;
  position: relative;

  @media (min-width: $s-tablet) {
    max-width: 360px;
  }
}

.guide__filters-chart {
  margin-top: 35px;

  > a {
    align-items: center;
    background-color: $tonightfilms;
    border-radius: 5px;
    color: #000000;
    display: flex;
    font-weight: 400;
    font-size: 16px;
    height: 50px;
    justify-content: center;
    letter-spacing: 0.32px;
    text-align: center;
    transition: background-color .3s ease, color .3s ease;
    width: 100%;

    &:before {
      font-size: 21px;
      margin-right: 25px;
    }

    &.is-active {
      color: $primary;
    }

    .no-touchevents &:hover {
      background-color: $tonightfilmsDarken;
    }
  }
}

.guide__filters-genres,
.guide__filters-channels {
  margin-top: 35px;

  > strong {
    color: #000;
    display: block;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.28px;
    text-align: center;
  }
}

.guide__filters-genres {
  background-color: #e9e9e9;
  border-radius: 5px;
  padding: 20px 15px;
  text-align: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 15px -7.5px -5px;

    > li {
      padding: 5px 7.5px;
      width: 50%;

      &.is-active {
        a {
          border: 1px solid $primary;
          color: $primary;
        }
      }
    }
  }

  a {
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    color: #333333;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    letter-spacing: 0.28px;
    line-height: 1.1;
    padding: 0 7px;
    text-align: center;
    transition: color .3s ease;

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.guide__filters-channels-tabs {
  display: flex;
  margin-top: 25px;

  > button {
    color: rgba(51, 51, 51, 0.55);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.32px;
    text-align: center;
    transition: color .3s ease;
    width: calc(100% / 2);

    &[selected] {
      outline: none;
    }

    &:after {
      background-color: #666;
      border-radius: 2px;
      content: "";
      display: block;
      height: 3px;
      margin-top: 8px;
      opacity: .4;
      transition: opacity .3s ease;
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }

    &[selected],
    .no-touchevents &:hover {
      color: #333;

      &:after {
        opacity: 1;
      }
    }

    &.--free {
      &:after {
        background-color: #58ab21;
      }
    }

    &.--premium {
      &:after {
        background-color: #ee5b2f;
      }
    }

    &.--sky {
      &:after {
        background-color: #337ab7;
      }
    }
  }
}

.guide__filters-channels-list {
  > div {
    display: none;

    &[selected] {
      display: block;
      outline: none;
    }
  }

  ul {
    margin-top: 20px;

    > li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &.is-active {
        a {
          border: 1px solid $primary;
          color: $primary;
        }
      }
    }
  }

  a {
    align-items: center;
    border: solid 1px $border;
    border-radius: 5px;
    color: #333;
    display: flex;
    height: 60px;
    overflow: hidden;
    padding-right: 15px;
    transition: color .3s ease;

    .no-touchevents &:hover {
      color: $primary;
    }

    > div {
      display: block;
      height: 60px;
      margin-right: 20px;
      position: relative;
      width: 60px;

      img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    small {
      flex-shrink: 0;
      font-size: 12px;
      letter-spacing: 0.24px;
      margin-left: auto;
    }

    span {
      font-size: 16px;
      letter-spacing: 0.32px;
    }
  }
}

.guide__categories {
  margin: 0 0 30px;

  @media (min-width: $s-tablet) {
    margin: 0 0 50px;
  }
}

.guide__categories-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;

  @media (min-width: $s-tablet) {
    margin: -14px;
  }
}

.guide__categories-item {
  flex: 0 0 auto;
  padding: 10px;
  width: calc(100% / 2);

  @media (min-width: $s-tablet) {
    padding: 14px;
    width: calc(100% / 3);
  }

  .guide__categories--bgcolor &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] & {
    &.guide__categories-item--today {
      > a, > button {
        background-color: #3cb318;
      }
    }

    &.guide__categories-item--allchannels {
      > a, > button {
        background-color: #c4a426;
      }
    }

    &.guide__categories-item--tonight {
      > a, > button {
        background-color: #c11adf;
      }
    }

    &.guide__categories-item--tonightfilms {
      > a, > button {
        background-color: $tonightfilms;
      }
    }

    &.guide__categories-item--sport {
      > a, > button {
        background-color: $sport;
      }
    }

    &.guide__categories-item--live {
      > a, > button {
        background-color: #df4444;
      }
    }
  }

  > a, > button {
    color: inherit;
    display: block;
    width: 100%;

    .guide__categories:not(.guide__categories--bgcolor):not(.guide__categories--bggrey) & {
      .no-touchevents &:hover {
        .guide__categories-title {
          border-color: $primary;
          color: $primary;
        }
      }
    }

    .guide__categories--bgcolor &,
    .guide__categories--bggrey &,
    body[amp-x-tv-guide-home-buttons="colored"] &,
    body[amp-x-tv-guide-evening-buttons="colored"] &,
    body[amp-x-tv-guide-home-buttons="neutral"] &,
    body[amp-x-tv-guide-evening-buttons="neutral"] & {
      align-items: center;
      backface-visibility: hidden;
      border-radius: 5px;
      display: flex;
      height: 80px;
      padding: 0 20px;
      justify-content: center;
      transform: translateZ(0);
      @include transition(transform, 1s);

      @media (min-width: $s-tablet) {
        height: 90px;
      }

      .no-touchevents &:hover {
        transform: translate3d(0, 0, 0) scale(1.07);
      }
    }

    .guide__categories--bggrey &,
    body[amp-x-tv-guide-home-buttons="neutral"] &,
    body[amp-x-tv-guide-evening-buttons="neutral"] & {
      background-color: #f7f7f7;
    }
  }
}

.guide__categories-title {
  border: 1px solid $border;
  border-radius: 5px;
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.31;
  padding: 10px 7px;
  text-align: center;
  transition: border-color .3s ease, color .3s ease;

  @media (min-width: $s-tablet) {
    font-size: 16px;
  }

  .guide__categories--bgcolor &,
  .guide__categories--bggrey &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] &,
  body[amp-x-tv-guide-home-buttons="neutral"] &,
  body[amp-x-tv-guide-evening-buttons="neutral"] & {
    font-size: 15px;
    line-height: 1;
    margin-top: 0;

    @media (min-width: $s-tablet) {
      font-size: 17px;
    }
  }

  .guide__categories--bgcolor &,
  body[amp-x-tv-guide-home-buttons="colored"] &,
  body[amp-x-tv-guide-evening-buttons="colored"] & {
    color: #fff;
  }

  .guide__categories--bgcolor .guide__categories-item--tonightfilms &,
  body[amp-x-tv-guide-home-buttons="colored"] .guide__categories-item--tonightfilms &,
  body[amp-x-tv-guide-evening-buttons="colored"] .guide__categories-item--tonightfilms & {
    color: #000;
  }
}

//Scheda del film
.movie__intro {
  background-color: #222;
  overflow: hidden;
  position: relative;
}

.movie__intro-bg {
  width: 100%;

  @media (max-width: ($l-tablet - 1)) {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  @media (min-width: $l-tablet) {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 5;
  }

  &:before {
    @media (min-width: $l-tablet) {
      background-color: rgba(0, 0, 0, 0.8);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 8;
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__intro-video {
  &.movie__intro-video--full {
    @media (min-width: $l-tablet) {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.95);
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      padding: 10vw;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1000000010; //sennò il banner pubblicitario va sopra

      &:before {
        display: none;
      }
    }

    @media (min-width: $extreme + 1) {
      padding: 15vw;
    }

    > div {
      @media (min-width: $l-tablet) {
        height: 0;
        position: relative;
        top: 0;
        transform: translateY(0);
      }
    }

    iframe {
      @media (min-width: $l-tablet) {
        height: 100%;
        width: 100%;
      }
    }
  }

  &:not(.movie__intro-video--full) {
    @media (min-width: $l-tablet) {
      &:before {
        background-color: rgba(34, 34, 34, 0.6);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 8;
      }
    }
  }

  > div {
    background-color: #000;
    height: 0;
    left: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 7;

    @media (min-width: $l-tablet) {
      height: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  iframe {
    height: 100%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @media (min-width: $l-tablet) {
      height: 56.25vw;
      width: 177.77777778vh;
    }
  }
}

.movie__intro-video-close {
  background-color: transparent;
  border: 0;
  color: #fff;
  display: none;
  font-size: 25px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  @include transition;
  z-index: 12;

  @media (min-width: $l-tablet) {
    font-size: 40px;
    right: 15px;
    top: 15px;
  }

  .movie__intro-video--full & {
    @media (min-width: $l-tablet) {
      display: block;

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.movie__intro-inner {
  padding: 20px 0;
  position: relative;
  z-index: 10;

  @media (min-width: $s-tablet) {
    padding: 50px 0 30px;
  }

  @media (min-width: $l-tablet) {
    padding: 50px 0 35px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ($l-tablet - 1)) {
      flex-direction: column;
    }
  }
}

.movie__intro-poster {
  flex-shrink: 0;
  position: relative;
  text-align: center;
  width: calc(100% / 3);

  @media (max-width: ($l-tablet - 1)) {
    order: 3;
    width: 100%;
  }

  .movie__intro--collapsed & {
    @media (min-width: $l-tablet) {
      width: 130px;
    }
  }

  > div:first-child {
    background-color: #fff;
    height: 0;
    padding-bottom: 150%;
    position: relative;

    @media (max-width: ($l-tablet - 1)) {
      display: none;
    }

    &.loading {
      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 15;
  }
}

.movie__intro-channels {
  border-top: 1px solid #444;
  margin-top: 20px;
  padding-top: 20px;
  position: relative;
  width: 100%;
  z-index: 5;

  @media (min-width: $s-tablet) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    padding-top: 10px;
    width: calc(100vw - (#{$container-padding} * 2));
  }

  @media (min-width: $container) {
    width: calc(#{$container} - (#{$container-padding} * 2));
  }

  .moviecard__footer & {
    border-top: none;
    margin-top: 0;
    padding-top: 0;

    @media (min-width: $s-tablet) {
      justify-content: flex-end;
      width: 100%;
    }
  }

  .movie__main & {
    border-top: none;
    margin-top: 0;
    width: 100%;
  }
}

.movie__intro-channel {
  align-items: center;
  color: $primary;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.14px;

  @media (min-width: $s-tablet) {
    margin-top: 20px;
    white-space: nowrap;
  }

  &:before {
    align-items: center;
    background-color: $primary;
    border-radius: 50%;
    color: #fff;
    display: flex;
    flex-shrink: 0;
    font-size: 8px;
    height: 23px;
    justify-content: center;
    margin-right: 8px;
    text-indent: 2px;
    width: 23px;
  }

  .no-touchevents &:hover {
    > span {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  &:not(:last-child) {
    @media (max-width: ($s-tablet - 1)) {
      border-bottom: 1px solid #444;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    @media (min-width: $s-tablet) {
      margin-right: 50px;
    }
  }

  &.icon-buy {
    &:before {
      content: "€";
      font-size: 15px;
      text-indent: 0;
    }
  }

  &.movie__intro-channel--netflix {
    color: $netflix;

    &:before {
      background-color: $netflix;
    }

    > span {
      &:after {
        background-color: $netflix;
      }
    }
  }

  &.movie__intro-channel--apple-tv-plus {
    color: $apple-tv-plus;

    &:before {
      background-color: $apple-tv-plus;
    }

    > span {
      &:after {
        background-color: $apple-tv-plus;
      }
    }
  }

  &.movie__intro-channel--amazon-prime-video {
    color: $amazon-prime-video;

    &:before {
      background-color: $amazon-prime-video;
    }

    > span {
      &:after {
        background-color: $amazon-prime-video;
      }
    }
  }

  &.movie__intro-channel--disney-plus {
    color: $disney-plus;

    &:before {
      background-color: $disney-plus;
    }

    > span {
      &:after {
        background-color: $disney-plus;
      }
    }
  }

  &.movie__intro-channel--chili {
    color: $chili;

    &:before {
      background-color: $chili;
    }

    > span {
      &:after {
        background-color: $chili;
      }
    }
  }

  &.movie__intro-channel--now-tv {
    color: $now-tv;

    &:before {
      background-color: $now-tv;
    }

    > span {
      &:after {
        background-color: $now-tv;
      }
    }
  }

  &.movie__intro-channel--timvision {
    color: $timvision;

    &:before {
      background-color: $timvision;
    }

    > span {
      &:after {
        background-color: $timvision;
      }
    }
  }

  &.movie__intro-channel--infinity {
    color: $infinity;

    &:before {
      background-color: $infinity;
    }

    > span {
      &:after {
        background-color: $infinity;
      }
    }
  }

  &.movie__intro-channel--sky-go {
    color: $sky-go;

    &:before {
      background-color: $sky-go;
    }

    > span {
      &:after {
        background-color: $sky-go;
      }
    }
  }

  &.movie__intro-channel--rai-play {
    color: $rai-play;

    &:before {
      background-color: $rai-play;
    }

    > span {
      &:after {
        background-color: $rai-play;
      }
    }
  }

  &.movie__intro-channel--apple-itunes {
    color: $apple-itunes;

    &:before {
      background-color: $apple-itunes;
    }

    > span {
      &:after {
        background-color: $apple-itunes;
      }
    }
  }

  &.movie__intro-channel--google-play-movies {
    color: $google-play-movies;

    &:before {
      background-color: $google-play-movies;
    }

    > span {
      &:after {
        background-color: $google-play-movies;
      }
    }
  }

  &.movie__intro-channel--microsoft-store {
    color: $microsoft-store;

    &:before {
      background-color: $microsoft-store;
    }

    > span {
      &:after {
        background-color: $microsoft-store;
      }
    }
  }

  &.movie__intro-channel--playstation {
    color: $playstation;

    &:before {
      background-color: $playstation;
    }

    > span {
      &:after {
        background-color: $playstation;
      }
    }
  }

  &.movie__intro-channel--rakuten-tv {
    color: $rakuten-tv;

    &:before {
      background-color: $rakuten-tv;
    }

    > span {
      &:after {
        background-color: $rakuten-tv;
      }
    }
  }

  &.movie__intro-channel--youtube-premium {
    color: $youtube-premium;

    &:before {
      background-color: $youtube-premium;
    }

    > span {
      &:after {
        background-color: $youtube-premium;
      }
    }
  }

  &.movie__intro-channel--mubi {
    color: $mubi;

    &:before {
      background-color: $mubi;
    }

    > span {
      &:after {
        background-color: $mubi;
      }
    }
  }

  &.movie__intro-channel--mediaset-play {
    color: $mediaset-play;

    &:before {
      background-color: $mediaset-play;
    }

    > span {
      &:after {
        background-color: $mediaset-play;
      }
    }
  }

  &.movie__intro-channel--discovery-plus {
    color: $discovery-plus;

    &:before {
      background-color: $discovery-plus;
    }

    > span {
      &:after {
        background-color: $discovery-plus;
      }
    }
  }

  &.movie__intro-channel--vvvvid {
    color: $vvvvid;

    &:before {
      background-color: $vvvvid;
    }

    > span {
      &:after {
        background-color: $vvvvid;
      }
    }
  }

  &.movie__intro-channel--starz-play-amazon-channel {
    color: $starz-play-amazon;

    &:before {
      background-color: $starz-play-amazon;
    }

    > span {
      &:after {
        background-color: $starz-play-amazon;
      }
    }
  }

  .movie__intro-poster & {
    @media (max-width: ($s-tablet - 1)) {
      border-top: 1px solid #333;
      margin-top: 15px;
      padding-top: 15px;
    }
  }

  .moviecard__footer & {
    margin-top: 10px;

    @media (max-width: ($s-tablet - 1)) {
      justify-content: center;
      text-align: center;
    }

    @media (min-width: $s-tablet) {
      margin-top: 0;
    }
  }

  .movie__main & {
    border-bottom: none;

    &:not(:last-child) {
      @media (max-width: $s-tablet - 1) {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }

  > span {
    line-height: initial;

    &:after {
      background-color: $primary;
      content: "";
      display: block;
      height: 1px;
      transform: scaleX(0);
      transform-origin: 0 0;
      @include transition(transform);
      width: 100%;
    }
  }
}

.movie__intro-info {
  color: #fff;
  flex: 1;
  width: 100%;

  @media (min-width: $l-tablet) {
    padding: 40px 50px 0;
  }

  .movie__intro--collapsed & {
    padding-top: 0;
  }

  h1, > strong {
    font-size: 25px;
    font-weight: 900;

    @media (min-width: $s-tablet) {
      font-size: 45px;
    }

    a {
      @include transition(color);

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }

  > span {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.1px;
    margin-top: 10px;

    @media (min-width: $s-tablet) {
      font-size: 20px;
      letter-spacing: 0.2px;
    }
  }
}

.movie__intro-meta {
  margin-top: 20px;

  > li {
    &:not(:last-child) {
      margin-bottom: 7px;

      @media (min-width: $s-tablet) {
        margin-bottom: 15px;
      }
    }
  }

  span {
    font-size: 14px;
    letter-spacing: 0.18px;
    line-height: 1.4;

    @media (min-width: $s-tablet) {
      font-size: 18px;
    }
  }

  a {
    color: $primary;

    .no-touchevents &:hover {
      text-decoration: underline;
    }
  }
}

.movie__intro-social {
  color: #fff;
  flex-shrink: 0;

  @media (min-width: $l-tablet) {
    margin-left: auto;
  }

  > ul {
    text-align: center;

    @media (max-width: ($l-tablet - 1)) {
      border-top: 1px solid #444;
      display: flex;
      margin-top: 30px;
      padding-top: 30px;
    }

    @media (max-width: ($s-tablet - 1)) {
      margin-top: 20px;
      padding-top: 20px;
    }

    @media (min-width: $l-tablet) {
      margin-top: 20px;
    }

    > li {
      &:not(:last-child) {
        @media (max-width: ($l-tablet - 1)) {
          margin-right: 35px;
        }

        @media (min-width: $l-tablet) {
          margin-bottom: 15px;
        }
      }
    }

    a {
      font-size: 15px;
      transition: color 0.3s ease;

      .no-touchevents &:hover {
        color: $primary;
      }
    }
  }
}

.movie__main {
  overflow: hidden;
  position: relative;

  > div {
    display: flex;
    flex-wrap: wrap;
  }
}

.movie__sections {
  width: 100%;
}

.movie__section {
  padding: 30px 0 25px;

  @media (min-width: $s-tablet) {
    padding: 55px 0 35px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $border;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.movie__section-back {
  margin-top: 20px;
  width: 100%;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }

  > a {
    align-items: center;
    display: inline-flex;
    font-size: 14px;
    font-weight: 900;
    @include transition(color);

    .no-touchevents &:hover {
      color: $primary;

      &:before {
        border-color: $primary;
      }
    }

    @media (max-width: ($s-tablet - 1)) {
      display: none;
    }

    &:before {
      align-items: center;
      border: solid 1px $border;
      border-radius: 50%;
      display: flex;
      font-size: 11px;
      flex-shrink: 0;
      height: 40px;
      justify-content: center;
      margin-right: 10px;
      @include transition(border-color);
      width: 40px;

      @media (min-width: $l-tablet) {
        font-size: 12px;
        height: 45px;
        width: 45px;
      }
    }
  }
}

.movie__section-title {
  font-size: 20px;
  font-weight: 900;
  position: relative;

  @media (min-width: $s-tablet) {
    font-size: 28px;
  }

  @media (max-width: $medium - 1) {
    align-items: center;
    display: flex;
  }

  .no-touchevents &:hover {
    .icon-anchor {
      @media (min-width: $medium) {
        opacity: 1;
      }
    }
  }

  .icon-anchor {
    flex-shrink: 0;
    font-size: 12px;
    margin-right: 5px;
    @include transition;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      margin-right: 7px;
    }

    @media (min-width: $medium) {
      margin-right: 0;
      opacity: 0;
      padding-right: 7px;
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
    }

    .no-touchevents &:hover {
      color: $primary;
    }
  }
}

.movie__section-text {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.21px;
  margin-top: 15px;

  @media ($s-tablet) {
    font-size: 21px;
    margin-top: 30px;
  }

  &:not(.is-expanded) {
    p {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a:not([class*="icon-"]) {
      background-image: linear-gradient(#fff 50%, rgba(232, 0, 0, 0.6) 0);
      background-repeat: repeat-x;
      background-position: 0 22px;
      background-size: 1px 1px;
      color: $primary;
      text-decoration: none;
      position: relative;
      text-shadow: 3px 0 #fff, 2px 0 #fff, 1px 0 #fff, -1px 0 #fff, -2px 0 #fff, -3px 0 #fff;
      z-index: 2;
    }
  }
}

.movie__section-cta {
  color: $primary;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.16px;
  margin-top: 25px;

  @media (min-width: $s-tablet) {
    margin-top: 35px;
  }

  &:after {
    background-color: $primary;
    content: "";
    display: block;
    height: 1px;
    margin-top: 2px;
    transform: scaleX(0);
    transform-origin: 0 0;
    @include transition(transform);
    width: 100%;
  }

  .no-touchevents &:hover {
    &:after {
      transform: scaleX(1);
    }
  }

  .movie__section-text &,
  .movie__season-text & {
    margin-top: 0;
  }

  .movie__season-text & {
    font-size: 14px;
  }
}

.movie__seasons {
  margin: 0 0 -15px;

  @media (min-width: $s-tablet) {
    margin: 15px 0 -15px;
  }
}

.movie__season {
  padding: 15px 0;
}

.movie__season-box {
  border: 1px solid $border;
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  padding: 15px;
}

.movie__season-poster {
  background-color: #fff;
  flex-shrink: 0;
  height: 83px;
  position: relative;
  width: 55px;

  @media (min-width: $s-tablet) {
    height: 195px;
    width: 130px;
  }

  &.loading {
    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }

  img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__season-caption {
  font-size: 16px;
  letter-spacing: 0.16px;
  padding: 10px 0 10px 15px;
  @include transition(border-color);
  width: 100%;

  @media (min-width: $s-tablet) {
    padding: 10px 0 10px 20px;
  }

  .no-touchevents .movie__season > a:hover & {
    border-color: #fff;
  }

  h3 {
    font-size: 18px;
    @include transition(color);

    @media (min-width: $s-tablet) {
      font-size: 20px;
    }

    .no-touchevents .movie__season > a:hover & {
      color: $primary;
    }
  }

  span {
    display: block;
    margin-top: 5px;

    @media (max-width: ($s-tablet - 1)) {
      font-size: 14px;
    }
  }
}

.movie__season-text {
  line-height: 1.56;
  margin: 57px 0 0 -70px;
  position: relative;

  @media (min-width: $s-tablet) {
    margin: 20px 0 0;
  }

  &:after {
    @media (max-width: ($s-tablet - 1)) {
      background-color: #f3f3f3;
      top: -10px;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  &:not(.is-expanded) {
    p {
      &:not(:first-child) {
        display: none;
      }
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $primary;

    .no-touchevents &:hover {
      text-decoration: underline;
    }
  }
}

.movie__season-ep {
  border-bottom: 1px solid $border;
  display: flex;
  padding: 20px 15px;
  position: relative;
}

.movie__season-ep-image {
  background-color: #fff;
  flex-shrink: 0;
  height: 74px;
  margin-right: 20px;
  position: relative;
  width: 130px;

  @media (max-width: ($s-tablet - 1)) {
    display: none;
  }

  &.loading {
    &:after {
      height: 15px;
      left: 50%;
      margin-left: -7.5px;
      margin-top: -7.5px;
      position: absolute;
      top: 50%;
      width: 15px;
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__season-ep-caption {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: ($s-tablet - 1)) {
    padding-right: 25px;
  }

  > b {
    display: flex;
    font-size: 14px;
    letter-spacing: 0.14px;

    @media (min-width: $s-tablet) {
      font-size: 16px;
      letter-spacing: 0.16px;
      max-width: calc(100% - 130px);
    }

    span {
      color: $primary;
      display: block;
      flex-shrink: 0;
      width: 65px;

      @media (min-width: $s-tablet) {
        width: 75px;
      }
    }
  }

  > button {
    height: 30px;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;

    @media (min-width: $s-tablet) {
      display: none;
    }

    &:before {
      color: #444;
      font-size: 10px;
    }
  }

  > span {
    color: #999;
    font-size: 12px;
    letter-spacing: 0.12px;

    @media (max-width: ($s-tablet - 1)) {
      margin-left: 65px;
      margin-top: 5px;
      width: 100%;
    }

    @media (min-width: $s-tablet) {
      font-size: 13px;
      letter-spacing: 0.13px;
      margin-left: auto;
    }
  }

  > div {
    font-size: 13px;
    line-height: 1.46;
    letter-spacing: 0.13px;
    margin: 15px 0 0;
    min-width: 100%;

    @media (max-width: ($s-tablet - 1)) {
      display: none;
    }

    &.is-expanded {
      display: block;
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 0;
      }
    }

    a {
      color: $primary;

      .no-touchevents &:hover {
        text-decoration: underline;
      }
    }
  }
}

.movie__stars {
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }
}

.movie__stars-list {
  margin: -10px -7.5px;

  @media (min-width: $s-tablet) {
    margin: -10px -12.5px;
  }

  &.swiper-container {
    @media (max-width: ($s-tablet - 1)) {
      margin: -10px 32.5px -10px -7.5px; //40 - margin
      overflow: visible;
    }
  }

  &:not(.swiper-container) {
    display: flex;
    flex-wrap: wrap;
  }
}

.movie__relateds {
  margin-top: 15px;

  @media (min-width: $s-tablet) {
    margin-top: 30px;
  }

  .swiper-container {
    overflow: visible;

    @media (max-width: ($s-tablet - 1)) {
      margin: 0 32.5px 0 -7.5px; //40 - margin
    }

    @media (min-width: $s-tablet) {
      margin: -12.5px;
    }
  }
}

.movie__poster {
  height: 100%;

  .movie__relateds & {
    display: block;
  }

  > a {
    border: 1px solid $border;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    transition: transform 0.3s ease-out, opacity 0.2s ease-out;
    transition-delay: 0.1s;

    .no-touchevents &:hover {
      transform: translate(0, -4px);
    }
  }
}

.movie__poster-image {
  background-color: #fff;
  flex-shrink: 0;
  height: 0;
  padding-bottom: 150%;
  position: relative;

  &.loading {
    &:after {
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      position: absolute;
      top: 50%;
      z-index: 10;

      @media (min-width: $s-tablet) {
        margin-left: -20px;
        margin-top: -20px;
      }
    }
  }

  > img {
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.movie__poster-caption {
  height: 100%;
  padding: 10px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    @include transition(color);

    .no-touchevents .movie__related > a:hover & {
      color: $primary;
    }
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.14px;
    margin-top: 5px;
  }
}

//Tags (Netflix, Prime Video ecc...)
.movie__tags {
  align-items: flex-start;
  bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  left: 10px;
  max-width: calc(100% - 20px);
  position: absolute;
  z-index: 10;

  .movie__intro-social & {
    position: static;
    max-width: none;
    width: auto;

    @media (max-width: ($l-tablet - 1)) {
      display: none;
    }

    @media (min-width: $l-tablet) {
      flex-direction: column;
      margin-top: 40px;
    }

    .movie__intro--collapsed & {
      margin-top: 0;
    }
  }
}

.movie__tag {
  background-color: #fff;
  border-radius: 3px;
  color: #111;
  display: block;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.1px;
  line-height: 1.6;
  margin-top: 7px;
  padding: 1px 7px;
  pointer-events: none;
  text-transform: uppercase;
  @include transition;

  @media (min-width: $s-tablet) {
    font-size: 12px;
    margin-top: 5px;
    padding: 3px 10px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.movie__tag--xs {
    @media (min-width: $s-tablet) {
      font-size: 10px;
      padding: 1px 10px;
    }
  }

  &.movie__tag--xl {
    @media (min-width: $s-tablet) {
      font-size: 13px;
      padding: 5px 10px;
    }
  }

  &.movie__tag--series {
    background-color: $series;
    color: #fff;
  }

  &.movie__tag--movie {
    background-color: $movie;
    color: #fff;
  }

  &.movie__tag--netflix {
    background-color: $netflix;
    color: #fff;
  }

  &.movie__tag--apple-tv-plus {
    background-color: $apple-tv-plus;
    color: #fff;
  }

  &.movie__tag--amazon-prime-video {
    background-color: $amazon-prime-video;
    color: #fff;
  }

  &.movie__tag--disney-plus {
    background-color: $disney-plus;
    color: #fff;
  }

  &.movie__tag--now-tv {
    background-color: $now-tv;
    color: #fff;
  }

  &.movie__tag--timvision {
    background-color: $timvision;
    color: #fff;
  }

  &.movie__tag--infinity {
    background-color: $infinity;
    color: #fff;
  }

  &.movie__tag--sky-go {
    background-color: $sky-go;
    color: #fff;
  }

  &.movie__tag--rai-play {
    background-color: $rai-play;
    color: #fff;
  }

  &.movie__tag--apple-itunes {
    background-color: $apple-itunes;
    color: #fff;
  }

  &.movie__tag--google-play-movies {
    background-color: $google-play-movies;
    color: #fff;
  }

  &.movie__tag--microsoft-store {
    background-color: $microsoft-store;
    color: #fff;
  }

  &.movie__tag--playstation {
    background-color: $playstation;
    color: #fff;
  }

  &.movie__tag--rakuten-tv {
    background-color: $rakuten-tv;
    color: #fff;
  }

  &.movie__tag--youtube-premium {
    background-color: $youtube-premium;
    color: #fff;
  }

  &.movie__tag--mubi {
    background-color: $mubi;
    color: #fff;
  }

  &.movie__tag--mediaset-play {
    background-color: $mediaset-play;
    color: #fff;
  }

  &.movie__tag--chili {
    background-color: $chili;
    color: #fff;
  }

  &.movie__tag--discovery-plus {
    background-color: $discovery-plus;
    color: #fff;
  }

  &.movie__tag--vvvvid {
    background-color: $vvvvid;
    color: #fff;
  }

  &.movie__tag--starz-play-amazon-channel {
    background-color: $starz-play-amazon;
    color: #fff;
  }

  .movie__intro-social & {
    @media (min-width: $l-tablet) {
      margin-right: 0;
      text-align: center;
      width: 100%;
    }
  }
}

//Star
.star__card {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;

  &.star__card--long {
    flex-direction: row;
    width: 100%;

    @media (min-width: $l-mobile) {
      width: 50%;
    }

    > a {
      flex-direction: row;
    }
  }

  &:not(.star__card--long) {
    .movie__stars &, {
      width: calc(100% / 2);

      @media (min-width: $s-mobile) {
        width: calc(100% / 3);
      }

      @media (min-width: $s-tablet) {
        width: calc(100% / 5);
      }
    }
  }

  .movie__stars & {
    padding: 10px 7.5px;

    @media (min-width: $s-tablet) {
      padding: 10px 12.5px;
    }
  }

  > a {
    display: flex;
    flex-direction: column;
  }
}

.star__card-image {
  .star__card--long & {
    flex-shrink: 0;
    margin-right: 15px;
    width: 120px;

    @media (min-width: $s-tablet) {
      margin-right: 25px;
      width: 130px;
    }
  }

  > div {
    border-radius: 5px;
    display: block;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;

    &.loading {
      background-color: $grey;

      &:after {
        left: 50%;
        margin-left: -15px;
        margin-top: -15px;
        position: absolute;
        top: 50%;
        z-index: 10;

        @media (min-width: $s-tablet) {
          margin-left: -20px;
          margin-top: -20px;
        }
      }
    }
  }

  img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center center;
    position: absolute;
    top: 0;
    @include transition(transform);
    width: 100%;

    .no-touchevents .star__card > a:hover & {
      transform: scale(1.1);
    }
  }
}

.star__card-caption {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.14px;
  padding: 10px 10px 0 3px;
}

.star__card-name {
  font-size: inherit;
  font-weight: 700;
  line-height: 1.15;
  @include transition(color);

  @media (min-width: $s-tablet) {
    font-size: 16px;
    letter-spacing: 0.16px;
  }

  .no-touchevents .star__card > a:hover & {
    color: $primary;
  }
}

.star__card-desc {
  color: #999;
  display: block;
  margin-top: 4px;
}

//Card movie
.movies__item {
  flex: 0 0 auto;
  height: auto;
  padding: 7.5px;
  width: calc(100% / 2);

  @media (min-width: $s-mobile) {
    width: calc(100% / 3);
  }

  @media (min-width: $s-tablet) {
    padding: 12.5px;
    width: calc(100% / 5);
  }

  @media (min-width: $l-tablet) {
    width: calc(100% / 6);
  }

  .movie__relateds & {
    @media (min-width: $s-tablet) {
      width: calc(100% / 4);
    }
  }
}
