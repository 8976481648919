@font-face {
  font-display: block;
  font-family: 'fontello';
  src: url('../font/fontello.eot?63578124');
  src: url('../font/fontello.eot?63578124#iefix') format('embedded-opentype'), url('../font/fontello.woff2?63578124') format('woff2'), url('../font/fontello.woff?63578124') format('woff'), url('../font/fontello.ttf?63578124') format('truetype'), url('../font/fontello.svg?63578124#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?63578124#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-angle-right:before {
  content: '\e801';
}
/* '' */
.icon-facebook:before {
  content: '\e803';
}
/* '' */
.icon-twitter:before {
  content: '\e804';
}
/* '' */
.icon-anchor:before {
  content: '\e808';
}
/* '' */
.icon-live:before {
  content: '\e80a';
}
/* '' */
.icon-star:before {
  content: '\e80f';
}
/* '' */
.icon-arrow-down:before {
  content: '\e820';
}
/* '' */
.icon-chart:before {
  content: '\e82d';
}
/* '' */
.icon-moon-full:before {
  content: '\e82f';
}
/* '' */
.icon-moon:before {
  content: '\e830';
}
/* '' */
.icon-coffee:before {
  content: '\e831';
}
/* '' */
.icon-morning:before {
  content: '\e832';
}
/* '' */
.icon-clock:before {
  content: '\e833';
}
/* '' */
.icon-instagram:before {
  content: '\e83e';
}
/* '' */
.icon-play:before {
  content: '\e860';
}
/* '' */
.icon-angle-left:before {
  content: '\e866';
}
/* '' */
.icon-arrow-left:before {
  content: '\e86f';
}
/* '' */
.icon-close:before {
  content: '\e874';
}
/* '' */
